import '../App.css';
import { Link }  from 'react-router-dom';
// import { BrowserRouter as Router, Routes, Route, Link }  from 'react-router-dom';

var Navbar = (props) => {
  const tab1Class = props.tab1Class + " nav-item-wrapper";

  return (
    <nav className="navbar">
      <div className={props.tab1Class}>
          <Link to="/info" className="nav-item link-text">Info</Link>
      </div>
      <div className={props.tab2Class}>
          <Link to="/mint" className="nav-item link-text">Mint</Link>
      </div>
    </nav>
  );

};

export default Navbar;
