import '../App.css';
// import Header from '../components/Header';
// import Background from '../components/BackgroundImage';
import Navbar from '../components/Navbar';
import MintWidget from '../components/MintWidget';
import NftCard from '../components/NftCard';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from '@web3-react/core'
import axios from 'axios';
import { useState } from 'react';

var Mint = (props) => {
  const entanglerAddress = '0xDC08aa5d94AC05F73DdB1F29a13C1b6e9c384980';

  // Set up web3-react
  const { account, activate, active, library: provider } = useWeb3React();
  const injected = new InjectedConnector();

  // Setup state
  let [usersEntangledMemes, setUsersEntangledMemes] = useState([]);
  let [preexistingNftCardsState, setPreexistingNftCardsState] = useState([]);

  let preexistingNfts = [];
  let preexistingNftCards = [];

  function getLibrary(provider) {
    return new Web3Provider(provider);
  }

  async function connect() {
    try{
      await activate(injected);
    } catch(e) {
      console.log('Error connectiong to provider', e)
    }
  }

  // console.log('{}{}{}', props.userNfts)

  // Create nft card elements in numerical order
  let nftCards = [];
  let orderedIndices = [];
  let lowest, lowestIdx;
  let allNftsFinished = true;
  for(let i = 0; i < props.userNfts.length; i++) {
    lowest = parseInt(props.userNfts[i][0].id);
    lowestIdx = i;
    // console.log('vv', i, props.userNfts.map(x => x))
    for(let j = 0; j < props.userNfts.length; j++) {
      if((parseInt(props.userNfts[j][0].id) < lowest && !orderedIndices.includes(j)) || (orderedIndices.includes(lowestIdx) && !orderedIndices.includes(j))) {
        lowest = parseInt(props.userNfts[j][0].id);
        lowestIdx = j;
        // console.log('lowest', lowest, lowestIdx)
      }
    }

    // console.log('Nfts complete?', props.userNfts[i][0])
    if(props.userNfts[i][0].img === null || props.userNfts[i][0].TVR === null) {
      // console.log('setting false')
      allNftsFinished = false;
    }

    for(let k = 0; k < props.userNfts[i][0].royalties.length; k++) {
      if(typeof props.userNfts[i][0].royalties[k] === 'undefined') {
        // console.log('setting false')
        allNftsFinished = false;
      }
    }

    orderedIndices.push(lowestIdx);
  }

  for(let i = 0; i < orderedIndices.length; i++) {
    let nft = props.userNfts[orderedIndices[i]];
    nftCards.push(<NftCard nft={nft[0]} />)
  }

  // Search for any pre-existing nfts
  if(active && preexistingNftCardsState.length === 0) {
    if(props.preexistingNfts.length > 0) {
      setPreexistingNftCardsState(props.preexistingNfts);
    } else {
      getPreexistingNfts();
    }

  }

  async function getPreexistingNfts(itr = 0) {
    // console.log(account)
    axios.get(`https://eth-mainnet.g.alchemy.com/nft/v2/pWHRbgDqpCxDUCh7jqGn1UwwP3gwZyv5/getNFTs?owner=${account}`)
    .then(res => {
      // console.log(res.data.ownedNfts)
      res.data.ownedNfts.forEach((item, i) => {
        // console.log(item)
        if(item.contract.address === entanglerAddress || item.contract.address === entanglerAddress.toLowerCase()) {

          let nft = {
            id: parseInt(item.id.tokenId, 16),
            royalties: [],
            mandates: [],
            TVR: 0,
            img: item.media[0].raw
          };

          item.metadata.attributes.forEach((attr, i) => {
            if(attr.trait_type !== 'TVR') {
              // console.log(attr)
              nft.royalties.push(attr.value);
              nft.mandates.push([attr.trait_type]);
            } else if(attr.trait_type === 'TVR') {
              nft.TVR = attr.value;
            }
          });

          // console.log(tmp)
          preexistingNfts.push(nft)
        }
      });

      for(let i = 0; i < preexistingNfts.length; i++) {
        preexistingNftCards.push(<NftCard nft={preexistingNfts[i]} />)
      }

      // console.log('---', preexistingNftCards)
      props.setPreexistingNfts(preexistingNftCards);
      setPreexistingNftCardsState(preexistingNftCards);
    })
    .catch(err => {
      console.log(err)
      if(itr < 50) {
        setTimeout(() => {
          getPreexistingNfts(itr++)
        }, 750 + Math.floor(Math.random() * 750))
      }
    })
  }

  // console.log('vvvvvvv', orderedIndices)

  return (
      <>
        <Navbar tab1Class="nav-item-wrapper1-unselected" tab2Class="nav-item-wrapper2-selected" />
        <div className="second-container pb-50">
          {active ? (
            <p className="mint-page-heder">
              Insert ether into the machine to mint memes
            </p>
          ) : (
            <p className="connect-wallet-page-heder">
              Connect wallet to begin
            </p>
          )}
          <MintWidget updateNfts={props.onNewNfts}
            waitingForUserToConfirm={props.waitingForUserToConfirm}
            waitingForTxToConfirm={props.waitingForTxToConfirm}
            waitingForRoyaltyGeneration={props.waitingForRoyaltyGeneration}
            missingRoyalties={props.missingRoyalties}
            setLatentNfts={props.setLatentNfts}
            onSetUserAccount={props.onSetUserAccount}
            onSetEntangler={props.onSetEntangler}
            onSetSigner={props.onSetSigner}
            onSetNftsToUpdate2={props.onSetNftsToUpdate2}
            onMintInProgress={props.onMintInProgress}
            onMissingRoyalties={props.onMissingRoyalties}
            onWaitingForUserToConfirm={props.onWaitingForUserToConfirm}
            onWaitingForTxToConfirm={props.onWaitingForTxToConfirm}
            onWaitingForRoyaltyGeneration={props.onWaitingForRoyaltyGeneration} />
          {props.mintInProgress ? (
            <>
            <div className="loading">Minting...</div>
            {props.waitingForUserToConfirm ? <div className="tx-processing-status">(waiting for tx to be approved)</div> : ''}
            {(props.waitingForTxToConfirm && !props.waitingForUserToConfirm) ? <div className="tx-processing-status">(transaction processing)</div> : ''}
            {(props.waitingForRoyaltyGeneration && !props.waitingForTxToConfirm && !props.waitingForUserToConfirm) ? <div className="tx-processing-status">(generating nft attributes)</div> : ''}
            </>
          ) : (
          <>
            </>
          )}
          {props.userNfts.length > 0 ? (
            <>
            <div>
              Results!
            </div>
            {allNftsFinished ? '' : <div className="results-subtext">(please be patient, image rendering and TVR calculation can take several minutes. do not refresh the page while you wait)</div>}
            {nftCards}
            </>
          ) : (
          <>
            </>
          )}
          {preexistingNftCardsState.length > 0 ? (
            <>
            <div className="m4">
              Your Memes
            </div>
            {preexistingNftCardsState}
            </>
          ) : (
          <>
            </>
          )}
        </div>
      </>
  );

};

export default Mint;
