import '../App.css';
import Loading from './Loading';
import loadingSpinner from '../imgs/loadingSpinner.gif';

var NftAttribute = (props) => {

  let tt;
  let tv = props.textValue[0];
  let loader;
  if(props.textValue[0] == 'WATER' ||
  props.textValue[0] == 'EARTH' ||
  props.textValue[0] == 'ATMOSPHERE' ||
  props.textValue[0] == 'PEOPLE') {
    // tv += (typeof props.textValue[1] !== 'undefined') ? (': ' + props.textValue[1]) : (': ' + 'loading')
    tv += (typeof props.textValue[1] !== 'undefined') ? (': ' + props.textValue[1]) : (': ')
    loader = (typeof props.textValue[1] !== 'undefined') ? ('') : <img className="loading-spinner" src={loadingSpinner} />
    tt = "the percentange (in basis points) of all economic activity concerning\nthis nft that should go to this category of charities\nValues range from 100-250"
  } else {
    tv += (typeof props.textValue[1] !== 'undefined') ? (': ' + props.textValue[1]) : ('')
    tt = "TVR (Total Value Raised) is the total USD that\nhas been put toward charity by this nft\n(sum total of all its categories)"
  }

  return (
    <span title={tt} className="nft-attribute">
      {tv}
      {loader}
    </span>
  );
};

export default NftAttribute;
