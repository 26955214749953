import '../App.css';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useState } from 'react';
import NftAttribute from './NftAttribute';
import renderingImage from '../imgs/vpcMinValuesRendering2.png';

var NftCard = (props) => {
  if(typeof props.nft !== 'undefined') {
    // console.log(props)
    let royalties = props.nft.royalties;
    let mandates = props.nft.mandates;
    let attributes = [];
    royalties.map((attr, i) => {
      if(i % 4 === 0) attributes.push(<br />)
      let tv;
      if(mandates[i][0]) {
        tv = [mandates[i][0], attr];
      } else {
        tv = ['err', 'err'];
      }
      attributes.push(<NftAttribute textValue={tv} />)
    })

    return (
      <div className="nft-card-box">
        {props.nft.img === null ? (
          <img className= "nft-card-img" alt="image rendering" src={renderingImage}></img>
        ) : (
          <>
          <img className= "nft-card-img" alt="nft image" src={props.nft.img}></img>
          </>
        )}
        <div className="nft-name-text">Entangled Meme #{props.nft.id}</div>
        {attributes}
        {props.nft.TVR === null ? (
          <>
          {(attributes.length % 5 === 0) ? <br /> : ""}
          <NftAttribute textValue={['calculating TVR']} />
          </>
      ) : (
        <>
        {(attributes.length % 5 === 0) ? <br /> : ""}
        <NftAttribute textValue={['TVR', props.nft.TVR]} />
        </>
      )}
    </div>
  );
}


};

export default NftCard;
