import '../App.css';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from '@web3-react/core'
import { useState, useEffect, useRef } from 'react';
import { ethers, BigNumber as BN } from 'ethers';
const entanglerAbi = require('../entanglerAbi.json');

var MintWidget = (props) => {
  const { account, activate, active, library: provider } = useWeb3React();
  const injected = new InjectedConnector();
  const entanglerAddress = '0xDC08aa5d94AC05F73DdB1F29a13C1b6e9c384980';
  const amountInput = useRef(null);
  const costVal = useRef(null);
  // let entangler;

  // Set up state
  let [entangler, setEntangler] = useState();
  let [amount, setAmount] = useState(1);
  let [unitPrice, setUnitPrice] = useState(0.01);
  let [cost, setCost] = useState(0.01);
  let [inputValue, setInputValue] = useState(0.01);
  let [usersEntangledMemes, setUsersEntangledMemes] = useState([]);
  let [rechecking, setRechecking] = useState([]);
  let [displayIncorrectAmountText, setDisplayIncorrectAmountText] = useState(false);

  // Runs every time the amount the user wishes to mint changes, tries to keep amount and the value of the text input consistant/orderly
  useEffect(() => {
    if(amountInput.current) {
      // console.log('///////', amountInput.current.value)
      if(!(amountInput.current.value === '' && amount === 0)) {
        amountInput.current.value = amount;
        // if(Number.isInteger(amountInput.current.value)) {
        //   amountInput.current.value = amount;
        // }
      }
    }
  })

  function getLibrary(provider) {
    return new Web3Provider(provider);
  }

  async function connect() {
    try{
      await activate(injected);
      setTimeout(() => {
        console.log('PROVIDER', provider)
        props.onSetSigner(provider.getSigner());
        props.onSetEntangler(new ethers.Contract(entanglerAddress, entanglerAbi, provider.getSigner()));
        props.onSetUserAccount(account);
      }, 60000)
    } catch(e) {
      console.log('Error connectiong to provider', e)
    }
  }

  const inputKeyPress = (e) => {
    // console.log('key type', (e.key), amountInput)
    if(e.key === '-') {
      // console.log(e.key)
      // amountInput.current.value = amount
    }
    // console.log('target', amountInput)
  }

  // Set amount and cost to reflect what the users inputting
  const inputAmountChange = (e) => {
    // if(!Number.isInteger(amountInput.current.value)) {
    //   console.log('Not valid int!', e.target.value, amountInput.current.value)
    // }
    if(e.target.value === '' || e.target.value < 0) {
      setAmount(0);
      setCost(0);
    } else {
      setAmount(parseInt(e.target.value));
      setCost(Math.round(parseInt(e.target.value) * unitPrice * 10000) / 10000);
    }
  }

  const plus = () => {
    setAmount(amount + 1);
    setCost(Math.round((amount + 1) * unitPrice * 10000) / 10000);
  }

  const minus = () => {
    if(amount > 0) {
      setAmount(amount - 1);
      setCost(Math.round((amount - 1) * unitPrice * 10000) / 10000);
    }
  }

  // Mint the desired amount of nfts and subsequently try to gather the data on them
  async function mint() {
    // Make sure that the user has entered a valid input for how many they want to mint
    if(amount > 0 && amountInput.current.value.indexOf('-') === -1 && amountInput.current.value.indexOf('.') === -1 && amountInput.current.value.indexOf('e') === -1) {
      // Check if the user has connected their wallet
      if(active) {
        props.onWaitingForUserToConfirm(true);

        // Set up the contract that we need to interact with
        const signer = provider.getSigner();
        setEntangler(new ethers.Contract(entanglerAddress, entanglerAbi, signer));
        let tmpEntangler;
        if(!entangler) {
          tmpEntangler = new ethers.Contract(entanglerAddress, entanglerAbi, signer);
        } else {
          tmpEntangler = entangler;
        }
        // entangler = new ethers.Contract(entanglerAddress, entanglerAbi, signer);
        props.onSetEntangler(new ethers.Contract(entanglerAddress, entanglerAbi, signer))
        props.onMintInProgress(true);

        // Mint the nfts
        try {
          let tx = await tmpEntangler.mint(account, 0, amount, { value: ethers.utils.parseEther(cost.toString()) })
          console.log('User approved Tx')
          props.onWaitingForUserToConfirm(false);

          let txRes = tx.wait();
          props.onWaitingForTxToConfirm(true);

          let txLogs = await txRes;
          console.log('Tx mined')
          props.onWaitingForTxToConfirm(false);
          props.onWaitingForRoyaltyGeneration(true);

          // Get the nft mandates
          let mandates;
          try {
            mandates = await tmpEntangler.getTokenTypeMandates(0)
            console.log('MANDATES', mandates)
          } catch(e) {
            console.log('Error getting mandates', e)
          }

          // Read the events from minting to get the ids of the nfts we minted
          txLogs = txLogs.events;
          let nftsMinted = [];
          let nftsMintedObj = {};
          txLogs.forEach((ev, i) => {
            let id = ev.args.id;
            // console.log('---', id.toNumber())
            nftsMinted.push(id.toNumber());
          });

          // console.log('***', nftsMinted)

          // Get the royalties for each nft that was minted
          let nftsReadyToCommit = [];
          let nftsMissingRoyalties = [];
          for(let i = 0; i < nftsMinted.length; i++) {

            let nft = nftsMinted[i];
            // console.log('CURR NFT', nft)

            // Wait a minute so that the mint watcher has time to generate and set the tokens royalties
            setTimeout(async () => {
              try {
                nftsMintedObj[nft] = await getNftRoyalties(nft)

                console.log('Get royalties result', nft, nftsMintedObj[nft])

                // Go through the royalties returned and check if any didn't get set
                for(let j = 0; j < nftsMintedObj[nft].length; j++) {
                  if(typeof nftsMintedObj[nft][j] === 'undefined') {
                    try {
                      nftsMintedObj[nft][j] = await retryRetrieveRoyalty(nft, j);
                    } catch(e) {
                      console.log('ERROR IN ROYALTY RETRY', e)
                    }
                  }
                }

                // Record the mandates that were retrieved earlier
                nftsMintedObj[nft].mandates = mandates;

                // Check if all royalties are set and if not, try to retrieve them again
                let readyToCommit = true;
                for(let j = 0; j < nftsMintedObj[nft].length; j++) {
                  if(typeof nftsMintedObj[nft][j] === 'undefined') readyToCommit = false;
                }

                if(!readyToCommit) {
                  // console.log('nft', nft, 'still missing some royalties', nftsMintedObj[nft].map(x => x))
                  await lastDitchRetry(nft, nftsMintedObj)
                  // console.log('LLLLLLLLLL resolved', nft)
                }

                nftsReadyToCommit.push(nft)

                if(readyToCommit) {
                  // console.log('Nft', nft, 'ready to commit.')
                } else {
                  // console.log('Nft', nft, 'not ready for commit, rerunning with delay', nftsMintedObj[nft].map(x => x))

                  // If nfts are still missing royalties, queue them instate so that the app will keep retrying to retrieve them
                  nftsMissingRoyalties.push(nft);
                }

                // console.log('Nfts ready to commit', nftsReadyToCommit)

                // Commint the results (so far) of the mint to the app's state
                if(nftsReadyToCommit.length === nftsMinted.length) { // if(i === nftsMinted.length - 1) {
                  checkForMissingRoyalties(nftsMissingRoyalties);
                  // console.log('Should be getting set to false')
                  props.onMintInProgress(false);
                  // console.log(nftsMintedObj)

                  props.onWaitingForRoyaltyGeneration(false);
                  props.updateNfts(nftsMintedObj);
                }
              } catch(e) {
                console.log('???????????', e)
              }
            }, 60000)
          }
        } catch(e) {
          console.log('User rejected tx?', e)
          props.onMintInProgress(false);
          props.onWaitingForUserToConfirm(false);
        }
      }

      console.log('mint')
    } else {
      console.log('Amount is not valid', amountInput.current.value.indexOf('-'), amountInput.current.value.indexOf('.'), amountInput.current.value.indexOf('e'))
      setDisplayIncorrectAmountText(true);
      setTimeout(() => {
        setDisplayIncorrectAmountText(false);
      }, 4000)
    }
  }

  // Retry any royalties that werent successfully collected right after mint
  async function lastDitchRetry(nft, nftsMintedObj) {
    return new Promise(async function(resolve, reject) {
    console.log('in invented timeout')
      setTimeout(async () => {
        console.log('In timeout')
        for(let j = 0; j < nftsMintedObj[nft].length; j++) {
          if(typeof nftsMintedObj[nft][j] === 'undefined') {
            try {
              console.log('RETRYING', nft, j)
              nftsMintedObj[nft][j] = await retryRetrieveRoyalty(nft, j);

              if(j === nftsMintedObj[nft].length - 1) {
                // console.log('IS RESOLVING')
                resolve();
              }
            } catch(e) {
              console.log('ERROR IN ROYALTY RETRY', e)
            }
          } else {
            if(j === nftsMintedObj[nft].length - 1) {
              // console.log('IS RESOLVING')
              resolve();
            }
          }
        }
      }, 5000)
    })
  }

  // Try to get the royalty of an nft at a specific index of its list of royalties
  async function retryRetrieveRoyalty(id, royaltyIdx, count = 0, countLimit = 10) {
    let tmpEntangler;
    if(!entangler) {
      const signer = provider.getSigner();
      tmpEntangler = new ethers.Contract(entanglerAddress, entanglerAbi, signer);
    } else {
      tmpEntangler = entangler;
    }

    return new Promise(async function(resolve, reject) {
      let tx;
      try {
        tx = tmpEntangler.royalties(id, royaltyIdx)
        .catch(err => {
          if(count > countLimit) reject(err)
        })
      } catch(e) {
        console.log('Error in retrying ryoalty', e)
        reject(e);
      }
      let txRes = await tx;
      resolve(txRes);
    })
  }

  // Try to get all the royalties of a specified nft
  async function getNftRoyalties(id) {
    return new Promise(async function(resolve, reject) {
      let nftRoyalties = Array(4);
      for(let i = 0; i < 4; i++) {
        await setTimeout(async () => {
          try{
            let tx = entangler.royalties(id, i);
            let txRes = await tx;
            nftRoyalties[i] = txRes;
            if(i === 3) {
              // console.log('RESOLVING', id)
              resolve(nftRoyalties)
            }
          } catch(e) {
            // console.log(';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;', id)
            if(i === 3) {
              // console.log('RESOLVING', id)
              resolve(nftRoyalties)
            }
          }
        }, 1000)
      }
    })
  }

  // Try to get the royalties for any nfts that were queued for a retry
  async function checkForMissingRoyalties(locMissingRoyalties, count = 0) {
    let resArr = [];
    setTimeout(async () => {
      if(locMissingRoyalties.length > 0) {
        // console.log('TTTTTTTTTTTT', locMissingRoyalties)

        let commitToState = false;
        for(let i = 0; i < locMissingRoyalties.length; i++) {
          if(!rechecking.includes(locMissingRoyalties[i])) {
            // console.log('NEED TO UPDATE STATE')
            commitToState = true;
          }
        }

        if(true) {
          for(let i = 0; i < locMissingRoyalties.length; i++) {
            // setTimeout(async () => {
            let nft = locMissingRoyalties[i];
            let nftObj = {
              id: nft,
              royalties: Array(4)
            }

            for(let j = 0; j < 4; j++) {
              try {
                // console.log('RETRYING', nft, j)
                nftObj.royalties[j] = await retryRetrieveRoyalty(nft, j, 0, 10);
                if(j === 3) {
                  // console.log('...........', nftObj)
                  let allSet = true;
                  nftObj.royalties.forEach((royalty, i) => {
                    if(typeof royalty === 'undefined') allSet = false;
                  });

                  if(allSet) {
                    // console.log('Passing nft', nftObj.id, 'as latent nft')
                    resArr.push([nftObj]);
                    // console.log('RES ARR', resArr.map(x => x))
                    props.setLatentNfts(resArr);
                    setTimeout(() => {
                      props.setLatentNfts(resArr);
                    }, 1000)
                    setTimeout(() => {
                      props.setLatentNfts(resArr);
                    }, 2000)
                    setTimeout(() => {
                      props.setLatentNfts(resArr);
                    }, 3000)
                    setTimeout(() => {
                      props.setLatentNfts(resArr);
                    }, 4000)
                  } else {
                    checkForMissingRoyalties([nftObj.id], count + 1)
                  }

                }
              } catch(e) {
                console.log('ERROR IN ROYALTY RETRY', e)
              }
            }
            // }, 15000)
          }

          // if(commitToState) setRechecking([...rechecking, ...locMissingRoyalties]);
        } else {
          console.log('Already rechecking', rechecking)
        }
      }
    }, 15000)
  }

  return (
    <>
      {active ? (
        <div className="mint-widget-box">
        <p className="mint-widget-header">Insert ether here</p>
        <div>
          Number of EMs:
          <button className="minus-btn" onClick={minus}>-</button>
          <input type="number" className="mint-amount" onChange={inputAmountChange} onKeyDown={inputKeyPress} defaultValue={amount} ref={amountInput}/>
          <button className="plus-btn" onClick={plus}>+</button>
        </div>
        <div>
          <div className="mint-cost" ref={costVal}>
            Cost: {cost} eth + gas fees
          </div>
          <button className="mint-btn" onClick={mint}>mint</button>
          {displayIncorrectAmountText ? <div className="fade-in">Please enter a valid amount to mint</div> : ''}
        </div>
      </div>
      ) : (
        <button className="connect-btn" onClick={() => connect()}>Connect</button>
      )}
      </>
  );

};

export default MintWidget;
