import '../App.css';

var Footer = () => {

  return (
    <footer className="footer">
      <div className="footer-text">Align Labs, LLC</div>
    </footer>
  );

};

export default Footer;
