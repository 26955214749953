import '../App.css';

var Header = () => {

  return (
    <header className="header">
      <h1 className="header-text">The Meme Entanglment Project</h1>
    </header>
  );

};

export default Header;
