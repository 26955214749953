// import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Background from './components/BackgroundImage';
import Navbar from './components/Navbar';
import Info from './pages/Info';
import Mint from './pages/Mint';
import { BrowserRouter as Router, Routes, Route, Link }  from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useState, useEffect } from 'react';
import { ethers, BigNumber as BN } from 'ethers';
import axios from 'axios';
const entanglerAbi = require('./entanglerAbi.json');
// import { useWeb3React } from '@web3-react/core'



function App() {

  // Set up state for the app
  let [userNfts, setUserNfts] = useState([]);
  let [mintInProgress, setMintInProgress] = useState(false);
  let [userAccount, setUserAccount] = useState();
  let [entangler, setEntangler] = useState();
  let [signer, setSigner] = useState();
  let [nftsBeingSearchedFor, setNftsBeingSearchedFor] = useState([]);
  let [nftsToUpdate, setNftsToUpdate] = useState([]);
  let [nftsToUpdate2, setNftsToUpdate2] = useState([]);
  let [missingRoyalties, setMissingRoyalties] = useState([]);
  let [waitingUserConfirmTx, setWaitingUserConfirmTx] = useState(false);
  let [waitingMintTxConfirm, setWaitingMintTxConfirm] = useState(false);
  let [waitingRoyaltyGeneration, setWaitingRoyaltyGeneration] = useState(false);
  let [holdMetadataReqs, setHoldMetadataReqs] = useState(false);
  let [latentNfts, setLatentNfts] = useState([]);
  let [preexistingNfts, setPreexistingNfts] = useState([]);

  // Variables for external resources
  const entanglerAddress = '0xDC08aa5d94AC05F73DdB1F29a13C1b6e9c384980';
  const metadataServerUrl = "https://alignlabs.io";
  // const metadataServerUrl = "http://54.193.114.123"

  // Used to add nfts to state initally after mint
  const addUserNfts = (newNfts) => {
    console.log('current state', userNfts)
    console.log('New nfts', newNfts)
    if(typeof entangler === 'undefined') setEntangler(new ethers.Contract(entanglerAddress, entanglerAbi, signer))

    let tmpArr = [];
    for(const nft in newNfts) {
      let tmp = {
        id: nft,
        royalties: newNfts[nft],
        TVR: null,
        img: null,
        mandates: newNfts[nft].mandates
      }
      // console.log('tmp', tmp)
      tmpArr.push([tmp]);
      // console.log('tmp arr', tmpArr)

      let newEMArray = [...userNfts, tmpArr]
      // console.log('newEmArr', newEMArray)
      setUserNfts([...userNfts, ...tmpArr])

    }
  }

  // Runs everytime state is updated, were using it mostly to keep state orderly and handle updates to the users nfts
  useEffect(() => {
    // console.log('Currently searching for:', nftsBeingSearchedFor)
    // Once we have some nfts that have been minted, start pinging the meatadata server to get their TVR and image
    for(let i = 0; i < userNfts.length; i++) {
      let nft = userNfts[i];
      if((nft[0].TVR === null || nft[0].img === null) && !nftsBeingSearchedFor.includes(nft[0].id)) {
        // console.log(999999999999999, nft)
          console.log('Should be talking to metadata server for', nft)
          setTimeout(() => {
              talkToMetadataServer(nft[0].id, userNfts)
          }, ((i + 1) * 1000))
        setNftsBeingSearchedFor([...nftsBeingSearchedFor, nft[0].id]);
      }
    }
    // console.log('Did it work?', userNfts)

    // Update any nfts with pending changes
    if(nftsToUpdate.length > 0) {
      updateNfts()
    }

    // Sometimes it takes a long time for the app to retrieve an nfts royalties after mint.
    // once they are found they get added to latent nfts array. here we check for those latent nft royalties
    // and merge them into the records of the users nfts
    if(latentNfts.length > 0) {
      updateNfts2();
    }

    // Check for duplicate nfts
    pruneUserNfts();

    // Make sure they're always displayed in the same order so they dont jump around on the screen when being updated
    orderNfts();
  })

  // Put users nfts into numerical order
  const orderNfts = () => {
    let tmpUserNfts = userNfts.map(x => x);
    // let fixedLength = tmpUserNfts.length;
    // let startingLen = tmpUserNfts.length;
    let orderedIndices = [];
    let resArr = [];

    // console.log('tmp arr', tmpUserNfts)
    // console.log('starting arr len', startingLen)

    for(let i = 0; i < tmpUserNfts.length; i++) {
      // console.log('i', i)
      let lowest = parseInt(tmpUserNfts[i][0].id);
      let lowestIdx = i;

      for(let j = 0; j < tmpUserNfts.length; j++) {
        // console.log('j', j)
        // console.log(parseInt(tmpUserNfts[j][0].id), lowest)
        if((parseInt(tmpUserNfts[j][0].id) < lowest && !orderedIndices.includes(j)) || (orderedIndices.includes(lowestIdx) && !orderedIndices.includes(j))) {
          lowest = parseInt(tmpUserNfts[j][0].id);
          lowestIdx = j;
          // console.log('lowest', lowest, lowestIdx)
        }
      }

      let pushVal = tmpUserNfts[lowestIdx];
      orderedIndices.push(lowestIdx);
      // tmpUserNfts.splice(lowestIdx);
      // startingLen--;
    }

    // console.log('SORTED:', orderedIndices, tmpUserNfts)

    for(let i = 0; i < orderedIndices.length; i++) {
      let nft = tmpUserNfts[orderedIndices[i]];
      resArr.push(nft)
    }
    // console.log('???????????', resArr)

    let needToUpdateState = false;
    for(let i = 0; i < orderedIndices.length - 1; i++) {
      if(orderedIndices[i + 1] - orderedIndices[i] !== 1) {
        // console.log('Need to update', orderedIndices[i + 1], orderedIndices[i])
        needToUpdateState = true;
      }
    }

    // console.log('Need to update state?', needToUpdateState)

    if(needToUpdateState) {
      setUserNfts(resArr);
    }

  }

  // Take any records of new information about the nfts we already have in state and merge them to the users nfts
  const updateNfts = () => {
    // console.log('OOOOOOOOOO', nftsToUpdate.map(x => x))
    if(userNfts.length > 0 && nftsToUpdate.length > 0) {
      for(let i = 0; i < nftsToUpdate.length; i++) {
        for(let nftIdx = 0; nftIdx < userNfts.length; nftIdx++) {
          if(userNfts[nftIdx][0].id == nftsToUpdate[i][0].id) {

            let tmpArr = userNfts;
            // console.log('[Tmp user nfts arr 0]', tmpArr)
            tmpArr.splice(nftIdx, 1);
            // console.log('[Tmp user nfts arr 1]', tmpArr)
            tmpArr.push(nftsToUpdate[i]);

            // console.log('[Tmp user nfts arr 2]', tmpArr)

            let tmpNftsToUpdate = nftsToUpdate;
            // console.log('[Tmp nfts to update arr 0]', tmpNftsToUpdate)
            tmpNftsToUpdate.splice(tmpNftsToUpdate.indexOf(nftsToUpdate[i]), 1)
            // console.log('[Tmp nfts to update arr 1]', tmpNftsToUpdate)

            setNftsToUpdate(tmpNftsToUpdate)
            // setUserNfts(tmpArr);
            // console.log('O1O1O1O1O1', userNfts, nftsToUpdate)
            break;
          }
        }
      }
    }
  }

  // Same as the first update function but specifically called for the latent royalties
  const updateNfts2 = () => {
    // console.log('OOOOOOOOOO', userNfts, nftsToUpdate)
    if(userNfts.length > 0 && latentNfts.length > 0) {
      console.log('LATENT NFTS', latentNfts)
      for(let i = 0; i < latentNfts.length; i++) {
        for(let nftIdx = 0; nftIdx < userNfts.length; nftIdx++) {
          if(userNfts[nftIdx][0].id == latentNfts[i][0].id) {
            let anyNewValues = false;
            console.log('NFT TO UPDATE', userNfts[nftIdx][0])
            console.log('NEW ROYALTIES', latentNfts[i][0])

            let tmpNft = userNfts[nftIdx][0];

            for(let j = 0; j < latentNfts[i][0].royalties.length; j++) {
              if(typeof tmpNft.royalties[j] === 'undefined') {
                anyNewValues = true;
                tmpNft.royalties[j] = latentNfts[i][0].royalties[j];
              }
            }

            if(anyNewValues) {
              console.log('NEW NFT', tmpNft)

              // console.log('[Tmp user nfts arr 2]', tmpArr)

              let tmpLatentNfts = latentNfts;
              // console.log('[Tmp nfts to update arr 0]', tmpLatentNfts)
              tmpLatentNfts.splice(tmpLatentNfts.indexOf(latentNfts[i]), 1)
              // console.log('[Tmp nfts to update arr 1]', tmpLatentNfts)

              setNftsToUpdate([...nftsToUpdate, [tmpNft]])
              // console.log('O1O1O1O1O1', userNfts, nftsToUpdate)
            }
            break;
          }
        }
      }
    }
  }

  // Get rid of any duplicates in our list of user nfts
  const pruneUserNfts = () => {
    for(let i = 0; i < userNfts.length; i++) {
      for(let j = i + 1; j < userNfts.length; j++) {
        let nft1 = userNfts[i][0];
        let nft2 = userNfts[j][0];

        if(nft1.id === nft2.id) {
          console.log(nft1);
          console.log(nft2);
          if(nft1.TVR !== null && nft1.img !== null) {
            let tmpArr = userNfts;
            let nft2idx = tmpArr.indexOf(nft2);
            tmpArr = tmpArr.splice(nft2idx, 1);
            // console.log('Deleting nft2')
            setUserNfts(tmpArr);
          } else if(nft2.TVR !== null && nft2.img !== null) {
            let tmpArr = userNfts;
            let nft1idx = tmpArr.indexOf(nft1);
            tmpArr = tmpArr.splice(nft1idx, 1);
            // console.log('Deleting nft1')
            setUserNfts(tmpArr);
          } else {
            let tmpArr = userNfts;
            let nft2idx = tmpArr.indexOf(nft2);
            tmpArr = tmpArr.splice(nft2idx, 1);
            // console.log('Deleting nft2')
            setUserNfts(tmpArr);
          }
        }
      }
    }
  }

  // Query the metadata server for each nfts full metadata until we get a response
  async function talkToMetadataServer(nft, localUserNfts) {
    let resultsFound = false;
    for(let i = 0; i < 40; i++) {
      let ms = ((i + 1) * 30000) // + (Math.random() * 90000)
      // console.log('MS', ms)

      let currNft = nft;
      setTimeout(async () => {

        let currNftFromState;
        for(let nftIdx = 0; nftIdx < userNfts.length; nftIdx++) {
          if(userNfts[nftIdx][0].id == nft) {
            currNftFromState = userNfts[nftIdx][0];
          }
        }

        if(!resultsFound && currNftFromState.TVR === null) {
          try {
            setHoldMetadataReqs(true);
            const res = await axios.get(`${metadataServerUrl}/metadata/v0/${nft}.json`)

            // console.log('Metadata server res', res)

            // Find the right nft in state
            let tmpRecord;
            for(let k = 0; k < localUserNfts.length; k++) {
              // console.log('eaxamining nft:', localUserNfts[k], localUserNfts[k][0].id)
              if(localUserNfts[k][0].id == currNft) {
                tmpRecord = localUserNfts[k][0];
              }
            }

            for(let j = 0; j < res.data.attributes.length; j++) {
              if(res.data.attributes[j].trait_type === 'TVR') {
                tmpRecord.TVR = res.data.attributes[j].value;
                tmpRecord.img = res.data.image;

                resultsFound = true;
              }

              // Fill in any blank royalties
              // console.log(res.data)
              if(res.data.attributes[j].trait_type === 'WATER' ||
                res.data.attributes[j].trait_type === 'PEOPLE' ||
                res.data.attributes[j].trait_type === 'EARTH' ||
                res.data.attributes[j].trait_type === 'ATMOSPHERE') {

                  if(typeof tmpRecord.royalties[j] === 'undefined') {
                    // let tmpRecord = localUserNfts[k][0];
                    tmpRecord.royalties[j] = res.data.attributes[j].value;
                  }

                  resultsFound = true;
              }

              setHoldMetadataReqs(false);

              // After last run, commit changes to state
              if(j === res.data.attributes.length - 1 && resultsFound === true) {
                await setNftsToUpdate([...nftsToUpdate, [tmpRecord]]);
              }
            }
          } catch(e) {
            setHoldMetadataReqs(false);
          }
        }
      }, ms)
    }
  }

  function getLibrary(provider) {
    return new Web3Provider(provider);
  }

  const addNftsWithoutRoyalties = (nftsToAdd) => {
    console.log('Adding new nft with missing royalties', missingRoyalties.map(x => x), nftsToAdd)
    setMissingRoyalties([...missingRoyalties, ...nftsToAdd]);
  }

  const getUserNftsFromState = () => {
    return userNfts;
  }

  // const displayNftState = () => {
  //   console.log('STATE:', userNfts)
  // }
  // <button onClick={displayNftState}>State</button>

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router>
        <div className="App">
        <Header />
          <div className="main-container">
            <Routes>
              <Route path="/" element={<Info tab1Class="nav-item-wrapper1-selected" tab2Class="nav-item-wrapper2-unselected" />}>
              </Route>
              <Route path="/info" element={<Info />}>
              </Route>
              <Route path="/mint" element={<Mint mintInProgress={mintInProgress}
                  userNfts={userNfts}
                  waitingForUserToConfirm={waitingUserConfirmTx}
                  waitingForTxToConfirm={waitingMintTxConfirm}
                  waitingForRoyaltyGeneration={waitingRoyaltyGeneration}
                  missingRoyalties={missingRoyalties}
                  latentNfts
                  setLatentNfts={setLatentNfts}
                  onNewNfts={addUserNfts}
                  onSetNftsToUpdate2={setNftsToUpdate2}
                  onSetUserAccount={setUserAccount}
                  onSetEntangler={setEntangler}
                  onSetSigner={setSigner}
                  onMintInProgress={setMintInProgress}
                  onMissingRoyalties={addNftsWithoutRoyalties}
                  onWaitingForUserToConfirm={setWaitingUserConfirmTx}
                  onWaitingForTxToConfirm={setWaitingMintTxConfirm}
                  onWaitingForRoyaltyGeneration={setWaitingRoyaltyGeneration}
                  preexistingNfts={preexistingNfts}
                  setPreexistingNfts={setPreexistingNfts} />}>
              </Route>
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </Web3ReactProvider>
  );
}

export default App;
