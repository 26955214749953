import '../App.css';
// import Header from '../components/Header';
// import Background from '../components/BackgroundImage';
import Navbar from '../components/Navbar';
import About from '../components/About';
import { useState } from 'react';

var Info = (props) => {
  // const [tabClasses, setTabClasses] = useState(["nav-item-wrapper1-selected", "nav-item-wrapper-unselected"])
  const tab1Class = props.tab1Class ? props.tab1Class : "nav-item-wrapper1-selected";
  const tab2Class = props.tab2Class ? props.tab2Class : "nav-item-wrapper2-unselected";

  let windowWidth = window.innerWidth;

  let containerClasses = `second-container text-align-left ${(windowWidth < 500) ? '' : 'padding-md'}`

  return (
      <>
        <Navbar tab1Class={tab1Class} tab2Class={tab2Class} />
        <div className={containerClasses}>
          <About />
        </div>
      </>
  );

};

export default Info;
