import '../App.css';

var About = () => {
  return (
    <>
      <h3 class="h3-1">
        &emsp;Background
      </h3>
      <p>
        &emsp;&emsp;The metavaerse is just the latest iteration on the information space that has been developing over millenia through inventions like language, writing, the printing press and the internet. In this information space nfts are just a new container for our ideas, similar to how we have captured our ideas in books, memes, flags and symbology, baseball cards, movies and works of art already.
Nfts are an internet native vessel for ideas with two interesting properties:
        <br />
        <br />
        <div class="div-1">
          One, they buck the traditional internet model where digital things only exist in one ecosystem (these ecosystems are best thought of as video games where the digital objects are all the characters, land, and items, but also apply to your social media and youtube profiles that only exist in their respective networks).
        </div>
        <br />
        <div class="div-1">
          Two, they have superpowers. Nfts can move money directly and people say that “money is power” and “time is money” and “money is the root of all evil” and a lot of other stuff about how powerful money is. A lot of other digital things move money as well, but they don’t do it natively. Massive companies build massive ecosystems so that their digital objects can do money things, but nfts do money natively, enabling them to do money things, but again without the dependence on a big, proprietary ecosystem.
        </div>
        <br />
        &emsp;&emsp;The intrigue of what new things could be built in the design space opened up by these characteristics is what brought about The Meme Entanglement Project.
      </p>
      <h3>
        &emsp;Zooming out a bit
      </h3>
      <p>
        &emsp;&emsp;Our physical space is shaped by some guiding forces and constraints. There are 118 elements on Earth that humans have to work with, along with physics and other natural constraints. Human imagination and ingenuity have to work within the bounds of these natural forces and that makes it so that nothing is free and everything is complex. The result is that all the good things we create come with negative externalities due to the challenges of bringing them into being. The information/digital space is not the same. Want a building in a book to be twice as tall? Backspace “500 feet” and type "1000" instead. No years of delay and millions over budget because the constraints of the physical world do not apply to the things we bring to life in the information space.
      <br />
      <br />
        &emsp;&emsp;In absence of these constraints, the main drivers that shape design choices in digital environments are expression, aesthetics, and meaning. The building looks exactly as its designer wants it to look in order for it to convey a story or emotion to the person experiencing that building. Having this space of pure creative expression is great, however, the ideas expressed therein are ideas formed from the broader reality of our existence that also includes the physical world. We believe that using a new vessel called nfts can allow ideas in the digital/information space to reach back into the physical world and affect change that is in accordance with the idea itself, and thereby give ideas more direct positive externalities.
      <br />
      <br />
        &emsp;&emsp;This is achieved in our design by building nfts with mechanisms and gamification for directing money to charities that are aligned with the values encapsulated by our nfts. The emission of money to charities is the positive externality of the expression of the ideas contained within these nfts. These positive externalities are tracked and presented as characteristics of the nfts that act as their DNA. They are a small set of meaningful metrics that represent something, but in and of themselves are just numbers. This is purposefully structured in a minimalistic way so that the nfts stay true to being an idea, not a thing. Anyone can create any thing that they like as an expression of the characteristics of the idea in whatever creative manner expresses their subjective interpretation, connection to, experience and understanding of the ideas.
      </p>
      <h3 class="h3-2">
        Entangled Memes
      </h3>
      <p>
        &emsp;&emsp;Entangled Memes are ideas (memes) in the information realm whose digital life is integrally entwined (entangled) with the real world roots of that idea. In order to create Entangled Memes, we invented the Meme Entanglement Engine (the Entangler), which you can interact with in the “Mint” section of this website. The Entangler takes raw ether as an input and transmutes it into Entangled Memes. The first batch of Entangled Memes (EM0) is now live and focuses on the broad idea of the flourishing of Earth and its inhabitants. This broad idea is broken down into four categories of charities:
      <br />
      <br />
      <div class="div-1">
        PEOPLE: focusing on the thriving and flourishing of all humans
      </div>
      <br />
      <div class="div-1">
        ATMOSPHERE: focusing on the health of the earth’s atmosphere and the living beings that reside there
      </div>
      <br />
      <div class="div-1">
      EARTH: focusing on the Earth’s lands and the living beings that reside there
      </div>
      <br />
      <div class="div-1">
      WATER: focusing on the health of the Earth’s waters and the living beings that reside there
      </div>
      <br />

        &emsp;&emsp;The EM0 batch of memes is limited to a supply of 11,888 memes and currently requires an input of 0.01 ether per meme produced. The amount of ether required will likely increase after the first 1,000 EMs have been brought into existence.
      <br />
      <br />
        &emsp;&emsp;The core characteristics of an Entangled Meme comprise of a baseline percent (in basis points) for each of its charitable categories. This is the portion of all economic activity involving the particular nft that should be channeled to the charities of that category. These are fixed values. In addition, there is a variable characteristic that tracks the total value that has been raised for charity so far by that nft. These simple characteristics are the DNA of an Entangled Meme and are free to be used, remixed, reimagined and interpreted into anything by anyone.
      </p>
    </>
  );

};

export default About;
